<template>
    <div class="home" v-if="data">
      <header class="flex py-10 ">
        <svg class="px-2" fill="#000000" height="40px" width="40px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M426.667,76.8c0-14.114-11.486-25.6-25.6-25.6c-14.114,0-25.6,11.486-25.6,25.6s11.486,25.6,25.6,25.6
				C415.181,102.4,426.667,90.914,426.667,76.8z M392.533,76.8c0-4.702,3.831-8.533,8.533-8.533c4.702,0,8.533,3.831,8.533,8.533
				c0,4.702-3.831,8.533-8.533,8.533C396.365,85.333,392.533,81.502,392.533,76.8z"/>
			<path d="M93.867,341.333c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h153.6c4.71,0,8.533-3.823,8.533-8.533
				s-3.823-8.533-8.533-8.533H93.867z"/>
			<path d="M358.4,76.8c0-14.114-11.486-25.6-25.6-25.6c-14.114,0-25.6,11.486-25.6,25.6s11.486,25.6,25.6,25.6
				C346.914,102.4,358.4,90.914,358.4,76.8z M324.267,76.8c0-4.702,3.831-8.533,8.533-8.533c4.702,0,8.533,3.831,8.533,8.533
				c0,4.702-3.831,8.533-8.533,8.533C328.098,85.333,324.267,81.502,324.267,76.8z"/>
			<path d="M247.467,85.333c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-153.6c-4.71,0-8.533,3.823-8.533,8.533
				s3.823,8.533,8.533,8.533H247.467z"/>
			<path d="M469.333,0H42.667C20.736,0,0,20.736,0,42.667v68.267c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V42.667
				c0-12.442,13.158-25.6,25.6-25.6h426.667c12.442,0,25.6,13.158,25.6,25.6V384c0,12.442-13.158,25.6-25.6,25.6H42.667
				c-12.442,0-25.6-13.158-25.6-25.6v-93.867h452.267c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H8.533
				C3.823,273.067,0,276.89,0,281.6V384c0,21.931,20.736,42.667,42.667,42.667h426.667C491.264,426.667,512,405.931,512,384V42.667
				C512,20.736,491.264,0,469.333,0z"/>
			<path d="M8.533,256c4.71,0,8.533-3.823,8.533-8.533V153.6h452.267c4.71,0,8.533-3.823,8.533-8.533
				c0-4.71-3.823-8.533-8.533-8.533H8.533c-4.71,0-8.533,3.823-8.533,8.533v102.4C0,252.177,3.823,256,8.533,256z"/>
			<path d="M375.467,349.867c0,14.114,11.486,25.6,25.6,25.6c14.114,0,25.6-11.486,25.6-25.6s-11.486-25.6-25.6-25.6
				C386.953,324.267,375.467,335.753,375.467,349.867z M409.6,349.867c0,4.702-3.831,8.533-8.533,8.533
				c-4.702,0-8.533-3.831-8.533-8.533s3.831-8.533,8.533-8.533C405.769,341.333,409.6,345.165,409.6,349.867z"/>
			<path d="M401.067,477.867H280.03c-2.577-7.228-8.269-12.919-15.497-15.497v-10.103c0-4.71-3.823-8.533-8.533-8.533
				s-8.533,3.823-8.533,8.533v10.103c-7.228,2.577-12.919,8.269-15.497,15.497H110.933c-4.71,0-8.533,3.823-8.533,8.533
				s3.823,8.533,8.533,8.533h121.045C235.511,504.849,244.89,512,256,512s20.489-7.151,24.03-17.067h121.037
				c4.71,0,8.533-3.823,8.533-8.533S405.777,477.867,401.067,477.867z M256,494.933c-4.702,0-8.533-3.831-8.533-8.533
				s3.831-8.533,8.533-8.533c4.702,0,8.533,3.831,8.533,8.533S260.702,494.933,256,494.933z"/>
			<path d="M93.867,204.8c-4.71,0-8.533,3.823-8.533,8.533c0,4.71,3.823,8.533,8.533,8.533h153.6c4.71,0,8.533-3.823,8.533-8.533
				c0-4.71-3.823-8.533-8.533-8.533H93.867z"/>
			<path d="M375.467,213.333c0,14.114,11.486,25.6,25.6,25.6c14.114,0,25.6-11.486,25.6-25.6c0-14.114-11.486-25.6-25.6-25.6
				C386.953,187.733,375.467,199.219,375.467,213.333z M409.6,213.333c0,4.702-3.831,8.533-8.533,8.533
				c-4.702,0-8.533-3.831-8.533-8.533c0-4.702,3.831-8.533,8.533-8.533C405.769,204.8,409.6,208.631,409.6,213.333z"/>
			<path d="M307.2,349.867c0,14.114,11.486,25.6,25.6,25.6c14.114,0,25.6-11.486,25.6-25.6s-11.486-25.6-25.6-25.6
				C318.686,324.267,307.2,335.753,307.2,349.867z M341.333,349.867c0,4.702-3.831,8.533-8.533,8.533
				c-4.702,0-8.533-3.831-8.533-8.533s3.831-8.533,8.533-8.533C337.502,341.333,341.333,345.165,341.333,349.867z"/>
			<path d="M307.2,213.333c0,14.114,11.486,25.6,25.6,25.6c14.114,0,25.6-11.486,25.6-25.6c0-14.114-11.486-25.6-25.6-25.6
				C318.686,187.733,307.2,199.219,307.2,213.333z M341.333,213.333c0,4.702-3.831,8.533-8.533,8.533
				c-4.702,0-8.533-3.831-8.533-8.533c0-4.702,3.831-8.533,8.533-8.533C337.502,204.8,341.333,208.631,341.333,213.333z"/>
		</g>
	</g>
</g>
</svg>
    <div class="flex flex-col justify-items-start justify-start items-start">
      <h1 class="text-lg font-bold">{{data.server.name}}</h1>
      <h2 class="text-md font-semibold">IP: {{ data.server.public_net.ipv4.ip}}</h2>
      <h5>#{{ data.server.id }}</h5>
    </div>
</header>
    
     <div class="grid grid-cols-4 gap-4">
    <button @click="onToggle(service)" v-for="service in data.services" :key="service" class="service-wrapper flex flex-col bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-4 px-4 rounded inline-flex items-center">
      <div :class="service + ' service'"></div> 
      <span class="mt-5">{{ getServiceLabel(service) }}</span>
    </button>
</div>







<div class="mt-10">

<h2>Backup and Restore</h2>
<p>
  This section allows you to back up and restore application data (files and database). You can create multiple on-demand backups. 
  In addition, Cloudlove offers option for scheduled and on-demand Server level backup that backs up all the applications deployed on the server.

</p>
<div class="flex mt-4 justify-start">
<select v-if="backups" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-80">
  <option v-for="file in backups.file" :key="file.filename">
    {{ file.date }} {{ file.filename }} ({{ file.size }})
  </option>
</select>
<button class="bg-pink-200 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
  Restore files Now
</button>
</div>
<div class="flex justify-start mt-4">

<select v-if="backups" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-80">
  <option v-for="db in backups.db" :key="db.filename">
    {{ db.date }} {{ db.filename }} ({{ db.size }})
  </option>
</select>
<button class="bg-pink-200 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
  Restore Database Now
</button>
    </div>
  </div>


  <div class="mt-10">
    <textarea v-model="stringify">
     
    </textarea>
  </div>


  <transition name="fade">
      <div v-if="isModalVisible && serviceRequested">
        <div
          @click="onToggle"
          class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
        ></div>
        <div
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto my-auto rounded-xl shadow-lg bg-white"
        >
          <div>
            <div class="text-center p-3 flex-auto justify-center leading-6">
              <h2 class="text-2xl font-bold py-4">Are you sure?</h2>
              <p class="text-md text-gray-500 px-8">
                Do you really want to run <b>{{ serviceRequested }}</b>?
              </p>
            </div>
            <div class="p-3 mt-2 text-center space-x-4 md:block">
              <button
              @click="runCommand(serviceRequested)"
                class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100"
              >
                Run <b>{{ serviceRequested }} now</b>
              </button>
              <button
                @click="onToggle(null)"
                class="mb-2 md:mb-0 bg-purple-500 border border-purple-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-purple-600"
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  //import Servers from '@/components/Servers.vue'
  import axios from "axios";
  const Axios = axios.create({
    baseURL: 'https://console.cloud.integrate.digital/api/'
  })

  export default {
    name: 'ServerDetail',
    data() {
    return {
      data: {},
      backups: [],
      isOpen: false,
      serviceRequested: null
    };
  },
  computed: {
    stringify(){
      return JSON.stringify(this.data)
    },
    isModalVisible() {
      return this.isOpen;
    }
  },
  methods:{
    onToggle(service) {
      this.isOpen = !this.isOpen;
      this.serviceRequested = service
    },
    getServiceLabel(service){
      let label = ''
      switch(service){
        case 'redis':
        case 'opcache':
        label = service + ' flush'
          break
        case 'apache':
        case 'memcached':
        case 'elasticsearch':
          label = service + ' restart'
          break
        case 'varnish':
          label = service + ' purge'
          break
        default:
          label = service
          break
      }

      return label
    },
    runCommand(service)
    {
      this.isOpen = false

      if(!service) return
      

      const self = this;
      const signInToken = localStorage.getItem('signInToken');
      Axios.defaults.headers.common = {'Authorization': `Bearer ${signInToken}`}

      Axios.get('?task=cliCommand&service=' + service + '&serverName=' + this.data.server.name)
      .then(function (response) {

        self.$root.$emit('updateQueue', self.data.server.name);
        console.log(response)
        
      })
      .catch(function (error) {
        console.log(error);
      });

      
    }
  },
    mounted(){ 

      

      const self = this;
      const signInToken = localStorage.getItem('signInToken');
      Axios.defaults.headers.common = {'Authorization': `Bearer ${signInToken}`}


      Axios.post('?task=getServer&serverName=' + this.$route.params.serverName)
      .then(function (response) {
        if(response.data.error !== undefined && 
          response.data.error === 'Expired token')
        {
          localStorage.removeItem('signInToken');
          self.$router.push('/login')
        }
        
        self.data = response.data;
        self.$root.$emit('updateQueue', self.$route.params.serverName);
      })
      .catch(function (error) {
        console.log(error);
      });


      Axios.post('?task=getBackups')
      .then(function (response) {
        console.log(response)
        self.backups = response.data;
      
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    components: {
      //Servers
    }
  }
  </script>
  <style scoped lang="scss">
  textarea{
    width: 100%;
    min-height: 400px;
    border: 1px solid #eee;
  }
  .service-wrapper{
    .service{
      width: 106px;
      height: 60px;
      border: none;
      background: url(~@/assets/ServicesLogos.svg) no-repeat center transparent;
      &.redis{
        background-position-y: -429px;
      }
      &.apache{
        background-position-y: -634px
      }
      &.memcached{
        background-position-y: -325px
      }
      &.elasticsearch{
        background-position-y: -850px
      }
      &.opcache{
        background-position-y: -535px
      }
    }
  }
  </style>